import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { useSpring, animated } from "react-spring"

export const AnimatedHeart = () => {
  const [toggle, setToggle] = useState(false)
  let count = 0 // Track iteration count
  const iterations = 5 // Total iterations for toggling

  useEffect(() => {
    const interval = setInterval(() => {
      setToggle(!toggle)
      count++
      if (count > iterations) {
        clearInterval(interval) // Stop after 20 iterations
        return () => clearInterval(interval)
      }
    }, 1000) // Toggle every 1 second

    return () => clearInterval(interval) // Cleanup function
  }, [toggle])

  const contentProps = useSpring({
    opacity: toggle ? 1 : 0.6,
    marginTop: toggle ? 0 : 2,
  })

  return (
    <div
      onClick={() => {
        navigate("valentinesDay")
      }}
      style={{
        cursor: "pointer",
      }}
    >
      <animated.svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width="48"
        height="48"
        style={contentProps}
      >
        <animated.path
          fill="red"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.655 14.9159C7.65523 14.9161 7.65543 14.9162 8 14.25C8.34457 14.9162 8.34477 14.9161 8.34501 14.9159C8.12889 15.0277 7.87111 15.0277 7.655 14.9159ZM7.655 14.9159L8 14.25L8.34501 14.9159L8.34731 14.9147L8.35269 14.9119L8.37117 14.9022C8.38687 14.8939 8.40926 14.882 8.4379 14.8665C8.49516 14.8356 8.57746 14.7904 8.6812 14.7317C8.8886 14.6142 9.18229 14.442 9.53358 14.2199C10.2346 13.7767 11.1728 13.13 12.1147 12.3181C13.9554 10.7312 16 8.35031 16 5.5C16 2.83579 13.9142 1 11.75 1C10.2026 1 8.84711 1.80151 8 3.01995C7.15289 1.80151 5.79736 1 4.25 1C2.08579 1 0 2.83579 0 5.5C0 8.35031 2.04459 10.7312 3.8853 12.3181C4.82717 13.13 5.76538 13.7767 6.46642 14.2199C6.81771 14.442 7.1114 14.6142 7.3188 14.7317C7.42254 14.7904 7.50484 14.8356 7.5621 14.8665C7.59074 14.882 7.61313 14.8939 7.62883 14.9022"
        />
      </animated.svg>

      {<div style={{ color: "red" }}>My heart has beat for you for:</div>}
      {<div style={{ color: "green" }}>5 years</div>}
      {<div style={{ color: "blue" }}>9 months</div>}
      {<div style={{ color: "pink" }}>29 days</div>}
      {<div style={{ color: "lightsalmon" }}>2 hours</div>}
      {<div style={{ color: "lightskyblue" }}>24 minutes</div>}
    </div>
  )
}
